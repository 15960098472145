import { useEffect } from 'react';

// Components
import Icon from './components/Icon.js'

// Data
import worlds from './data/worlds.json'
import { images } from './data/data.js'

import { viewer, setViewer } from './App.js'
import { getFriendlyName, imageSRC, getThumbSrc } from './functions.js';

export default function ImgEnlarged({ world, index }) {
    let data = worlds[world];
    let friendly = getFriendlyName(world);
    let worldImages = images[world];
    index = Number(index);

    let fullSRC = imageSRC(world, index);
    let thumbSRC = `${getThumbSrc(friendly, worldImages[index])}`;

    let src = fullSRC;

    // Caption
    let caption = data?.image_caption?.[index+1];
    caption =  caption === undefined ?
        <span className="gray">No caption</span> :
        <i>"{caption}"</i>;


    /** Navigate images */
    function navigate(dir=0) {
        if(!dir) return;

        let to = Number(index) + dir;
        if(to < 0 || to > worldImages.length-1) return; // Beginning/end

        setViewer({...viewer, index:to});
    }

    /** Close image viewer */
    function closeViewer() {
        setViewer({...viewer, world:undefined});
        // document.exitFullscreen();
    }

    // Keydown event
    useEffect(() => {
        // Add listeners
        document.addEventListener('keydown', keyDownHandler);

        // Remove listeners
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        }; 

        /** Key down handler */
        function keyDownHandler({ key }) {
            // Exit
            if(key === "Escape") closeViewer();

            // Navigate
            else if(key === "ArrowLeft") navigate(-1);
            else if(key === "ArrowRight") navigate(1);

            // Fullscreen
            else if(key.toUpperCase() === 'F') {
                document.fullscreenElement === null ?
                    document.body.requestFullscreen() :
                    document.exitFullscreen();
            }
        };
    }, [index]);

    return (
        <>
            <div className="backdrop" onClick={closeViewer}/>
            <div
                autoFocus
                className="enlarged idle"
                onWheel={event => {
                    let dir = Math.sign(event.deltaY);
                    navigate(dir);
                }}
            >
                <img
                    key={src}
                    src={src} alt={fullSRC}
                    id="image" onClick={closeViewer}
                    style={{ "--thumb": `url(${thumbSRC})` }}
                />

                <img-info className="alt_text">
                    <div className="left">
                        <p data-title="Click to copy image URL" class="title_above">
                            {fullSRC.substring(fullSRC.lastIndexOf('/') + 1)}
                        </p>
                        <strong>
                            <span>{index+1} / {worldImages.length}</span> {index === data.header_image ?
                            <Icon icon="star" className="small title_above" title="Header Image"/> : null}
                        </strong>
                    </div>
                    <div className="right">
                        <p className="alt_text">{caption}</p>
                        <p>Source: <strong>{data.image_credit?.[index+1] ?? 'NotNone'}</strong></p>
                    </div>
                </img-info>
            </div>
        </>
    )
}