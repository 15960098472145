// Components
import Icon from './Icon'

// Data
import data from '../data/data.json'

export default function Tag({ name, after, className="", onClick, toggle }) {
    let td = data.tags?.[name];
    if(td === undefined) td = { ...data.tags.und, label:name };
    let {label, icon, color} = td;
    return (
        <div className={"tag " + (toggle?"toggle_tag ":"") + className} style={{"--color": color}} onClick={onClick} tabIndex={onClick?0:-1} role={onClick?"button":null}>
            <Icon icon={icon}/>
            <span>{label}</span>
            <span className="after">{after}</span>
        </div>
    )
}
