import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Assets
// import Icon from './Icon'
import './App.css'

// Components
import Home from './home/Home'
import WorldPage from './WorldPage'
// import Page404 from './Page404'
import ImgEnlarged from './ImgEnlarged'
import PlayerStats from './PlayerStats'

import worlds from './data/worlds.json'
import { images } from './data/data.js'
import { getFriendlyName, arrayRandom } from './functions'

console.log(Object.keys(worlds));

// Menus
let viewer, setViewer; // Image viewer
let statsMenu, setStatsMenu; // Statistics menu
let bg_src, set_bg_src; // Background image
export {
    viewer, setViewer,
    statsMenu, setStatsMenu,
    bg_src, set_bg_src
}

export default function App() {
    // State
    const [filters, setFilters] = useState({});
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [sort, setSort] = useState('relevant');
    const [homeTab, setHomeTab] = useState('Worlds');
    
    function toggleFilterMenu() {
        setFiltersOpen(prev => !prev);
        if(filtersOpen) setFilters({}); // Clear filters
    }

    function toggleFilter(name) {
        let newFilters = {...filters};
        newFilters[name] = !newFilters[name];

        // Mutually exclusive
        const exclusivePairs = [
            ["Modded", "Vanilla"],
            ["Survival", "Creative"],
            ["Singleplayer", "Multiplayer"],
        ]
        for(const [one, two] of exclusivePairs) {
            if(name === one && newFilters[two]) newFilters[two] = false;
            if(name === two && newFilters[one]) newFilters[one] = false;
        }

        setFilters(newFilters);

        // Open menu
        if(!filtersOpen) toggleFilterMenu();
    }
    
    // Random BG image
    // let randomWorld = arrayRandom(Object.keys(images));
    let randomWorld = arrayRandom(["Terralith CO-OP", "Creative Realm"]);
    let randomHeader = `https://worlds-img.notkal.com/${getFriendlyName(randomWorld)}/${arrayRandom(images[randomWorld])}`;

    // BG image state
    [bg_src, set_bg_src] = useState(randomHeader);
    let bg_image_style = { "--image": `url('${bg_src}')` };

    // Image viewer state
    [viewer, setViewer] = useState({
        world: undefined, index: 0, src:""
    });

    // Statistics menu state
    [statsMenu, setStatsMenu] = useState({
        open:false, world: undefined, username:""
    });

    return (
        <>
            <div id="bg_image" style={bg_image_style}></div>
            <BrowserRouter acrollRestoration="auto">
                <Routes>
                    <Route path="/" element={
                        <Home
                            filters={filters} setFilters={setFilters}
                            filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen}
                            sort={sort} setSort={setSort}
                            homeTab={homeTab} setHomeTab={setHomeTab}
                            toggleFilter={toggleFilter} toggleFilterMenu={toggleFilterMenu}
                        />
                    } />
                    {Object.keys(worlds).map(name => {
                        let friendly = getFriendlyName(name);
                        return <Route path={friendly} element={
                            <WorldPage name={name} key="name"
                                toggleFilter={toggleFilter}toggleFilterMenu={toggleFilterMenu}
                            />
                        }></Route>
                    })}
                </Routes>

            </BrowserRouter>

            {/* Image viewer */}
            {
                viewer.world ? <ImgEnlarged world={viewer.world} src={viewer.src} index={viewer.index} /> : ''
            }

            {/* Player stats */}
            {
                statsMenu.open ? <PlayerStats world={viewer.world} src={viewer.src} index={viewer.index} /> : ''
            }
        </>
    )
}
