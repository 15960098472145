const icon_data = {
    star:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='m223-69 69-289L64-552l299-25 117-272 117 273 299 24-228 194 69 289-257-153L223-69Z'/></svg>,
    singleplayer:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='M480-492q-70 0-118-48t-48-118q0-70 48-118t118-48q70 0 118 48t48 118q0 70-48 118t-118 48ZM162-131v-127q0-30 14.688-54.854Q191.375-337.708 217-354q59-35 126.408-53.5 67.407-18.5 136.5-18.5Q549-426 616.5-407.5 684-389 743-354q25.625 15.292 40.312 40.646Q798-288 798-258v127H162Z'/></svg>,
    multiplayer:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='M32-248v-73q0-47 44-75t116-28q6 0 9.5.5t8.5.5q-15 22-22.5 47.972T180-322v74H32Zm216 0v-74q0-31 15-56.5t47-44.5q32-19 74.5-28t95.957-9q53.543 0 95.043 9 41.5 9 73.5 28 32 18 47.5 44.188Q712-352.624 712-322v74H248Zm532 0v-74q0-27.37-8-52.62-8-25.25-23-48.38 3 0 6.614-.5 3.615-.5 12.386-.5 73.2 0 116.6 28.371Q928-367.258 928-321v73H780ZM192.089-468Q158-468 134-491.886t-24-57.939Q110-584 133.886-608t57.939-24Q226-632 250-608.15q24 23.849 24 58.061Q274-516 250.15-492q-23.849 24-58.061 24Zm576 0Q734-468 710-491.886t-24-57.939Q686-584 709.886-608t57.939-24Q802-632 826-608.15q24 23.849 24 58.061Q850-516 826.15-492q-23.849 24-58.061 24ZM480-507q-51.667 0-87.833-36.187Q356-579.374 356-631.07q0-50.93 36.187-87.43Q428.374-755 480.07-755q50.93 0 87.43 36.55Q604-681.9 604-631q0 51.667-36.55 87.833Q530.9-507 480-507Z'/></svg>,



    vanilla:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='M480-48 290-432q-63-1-104.5-42T144-576q0-45 27.5-82t72.5-54q16-88 82-144t154-56q88 0 154 56t82 144q45 17 72.5 54t27.5 82q0 61-41 102t-103 42L480-48Zm0-163 120-241-18-4q-21 12-46.5 18t-55.5 6q-29 0-54.5-6T378-456l-18 3 120 242Z'/></svg>,
    modded:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='M666-163 475-354q-20 8-43.5 12.5T384-337q-99 0-169.5-70T144-576q0-38 9.5-72t28.5-63l144 144 70-70-144-144q29-17 62.5-26t69.5-9q100 0 170 71t70 170q0 23-4.5 42.5T607-493l195 194q14 14 14 34.5T802-230l-68 67q-14 14-34 14t-34-14Z'/></svg>,

    description:
        <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M305-224h351v-100H305v100Zm0-170h350v-100H305v100ZM236-48q-43.725 0-74.863-31.138Q130-110.275 130-154v-652q0-43.725 31.137-74.862Q192.275-912 236-912h312l282 281v477q0 43.725-31.138 74.862Q767.725-48 724-48H236Zm260-530h228L496-806v228Z'/></svg>,
    calendar:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='M480.211-378Q463-378 450.5-390.289q-12.5-12.29-12.5-29.5Q438-437 450.289-449.5q12.29-12.5 29.5-12.5Q497-462 509.5-449.711q12.5 12.29 12.5 29.5Q522-403 509.711-390.5q-12.29 12.5-29.5 12.5Zm-156 0Q307-378 294.5-390.289q-12.5-12.29-12.5-29.5Q282-437 294.289-449.5q12.29-12.5 29.5-12.5Q341-462 353.5-449.711q12.5 12.29 12.5 29.5Q366-403 353.711-390.5q-12.29 12.5-29.5 12.5Zm312 0Q619-378 606.5-390.289q-12.5-12.29-12.5-29.5Q594-437 606.289-449.5q12.29-12.5 29.5-12.5Q653-462 665.5-449.711q12.5 12.29 12.5 29.5Q678-403 665.711-390.5q-12.29 12.5-29.5 12.5Zm-156 144Q463-234 450.5-246.289q-12.5-12.29-12.5-29.5Q438-293 450.289-305.5q12.29-12.5 29.5-12.5Q497-318 509.5-305.711q12.5 12.29 12.5 29.5Q522-259 509.711-246.5q-12.29 12.5-29.5 12.5Zm-156 0Q307-234 294.5-246.289q-12.5-12.29-12.5-29.5Q282-293 294.289-305.5q12.29-12.5 29.5-12.5Q341-318 353.5-305.711q12.5 12.29 12.5 29.5Q366-259 353.711-246.5q-12.29 12.5-29.5 12.5Zm312 0Q619-234 606.5-246.289q-12.5-12.29-12.5-29.5Q594-293 606.289-305.5q12.29-12.5 29.5-12.5Q653-318 665.5-305.711q12.5 12.29 12.5 29.5Q678-259 665.711-246.5q-12.29 12.5-29.5 12.5ZM210-66q-40.425 0-69.212-29.138Q112-124.275 112-164v-540q0-39.725 28.788-68.862Q169.575-802 210-802h40v-92h98v92h264v-92h98v92h40q40.425 0 69.213 29.138Q848-743.725 848-704v540q0 39.725-28.787 68.862Q790.425-66 750-66H210Zm0-98h540v-384H210v384Z'/></svg>,
    chart:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='M630-160v-323h170v323H630Zm-235 0v-640h170v640H395Zm-235 0v-439h170v439H160Z'/></svg>,


    heart:
        <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 -960 960 960' width='20'><path d='m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z'/></svg>,
    heart_monitor:
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m425-445-36-55q-5-8-13-12t-17-4H112q-9-23-12.5-44.5T96-605q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 23-4 45t-13 45H617l-60-93q-5-8-13.5-12t-17.5-4q-11 0-18.5 6T495-602l-70 157Zm55 325-50-45q-108-96-174-162T153-444h189l60 93q5 8 13.5 11.5T433-336q11 0 19-5.5t12-15.5l70-156 35 54q5 8 13 12t17 4h207q-37 51-103.5 117T528-163l-48 43Z"/></svg>,
    cube:
        <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M427-61 141-225q-25-14-39-38.5T88-317v-325q0-29 14-53.5t39-38.5l286-164q25-14 53-14t53 14l285 164q25 14 39 38.5t14 53.5v325q0 29-14 53.5T818-225L533-61q-25 14-53 14t-53-14Zm1-387v264l52 30 53-31v-264l232-135v-56l-56-33-229 133-229-133-57 33v55l234 137Z'/></svg>,

    crown:
        <><svg fill='#000000' height='800px' width='800px' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 267.5 267.5'><path d='M256.975,100.34c0.041,0.736-0.013,1.485-0.198,2.229l-16.5,66c-0.832,3.325-3.812,5.663-7.238,5.681l-99,0.5c-0.013,0-0.025,0-0.038,0H35c-3.444,0-6.445-2.346-7.277-5.688l-16.5-66.25c-0.19-0.764-0.245-1.534-0.197-2.289C4.643,98.512,0,92.539,0,85.5c0-8.685,7.065-15.75,15.75-15.75S31.5,76.815,31.5,85.5c0,4.891-2.241,9.267-5.75,12.158l20.658,20.814c5.221,5.261,12.466,8.277,19.878,8.277c8.764,0,17.12-4.162,22.382-11.135l33.95-44.984C119.766,67.78,118,63.842,118,59.5c0-8.685,7.065-15.75,15.75-15.75s15.75,7.065,15.75,15.75c0,4.212-1.672,8.035-4.375,10.864c0.009,0.012,0.02,0.022,0.029,0.035l33.704,45.108c5.26,7.04,13.646,11.243,22.435,11.243c7.48,0,14.514-2.913,19.803-8.203l20.788-20.788C238.301,94.869,236,90.451,236,85.5c0-8.685,7.065-15.75,15.75-15.75s15.75,7.065,15.75,15.75C267.5,92.351,263.095,98.178,256.975,100.34z M238.667,198.25c0-4.142-3.358-7.5-7.5-7.5h-194c-4.142,0-7.5,3.358-7.5,7.5v18c0,4.142,3.358,7.5,7.5,7.5h194c4.142,0,7.5-3.358,7.5-7.5V198.25z'/></svg></>,
    download:
        <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M479-314 239-554l75-75 112 112v-315h106v315l112-112 75 75-240 240ZM234-128q-43.725 0-74.863-31.137Q128-190.275 128-234v-120h106v120h492v-120h106v120q0 44-31.138 75-31.137 31-74.862 31H234Z'/></svg>,
    compare:
        <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='m304-128-74-76 92-92H48v-106h274l-92-92 74-76 222 221-222 221Zm352-262L434-611l222-221 74 76-92 92h274v106H638l92 92-74 76Z'/></svg>,
    swap:
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M296-434v-274l-92 92-76-74 221-222 221 222-76 74-92-92v274H296ZM611-48 390-270l76-74 92 92v-274h106v274l92-92 76 74L611-48Z"/></svg>,
    sell:
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M856-390 570-104q-12 12-27 18t-30 6q-15 0-30-6t-27-18L103-457q-11-11-17-25.5T80-513v-287q0-33 23.5-56.5T160-880h287q16 0 31 6.5t26 17.5l352 353q12 12 17.5 27t5.5 30q0 15-5.5 29.5T856-390ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640Z"/></svg>,

    open_in_full:
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M100.782-100.782v-346.001h106.001v165.347l471.781-471.781H513.217v-106.001h346.001v346.001H753.217v-165.347L281.436-206.783h165.347v106.001H100.782Z"/></svg>,
    filter:
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M440-160q-17 0-28.5-11.5T400-200v-240L168-736q-15-20-4.5-42t36.5-22h560q26 0 36.5 22t-4.5 42L560-440v240q0 17-11.5 28.5T520-160h-80Z"/></svg>,
    sort:
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M120-240v-80h240v80H120Zm0-200v-80h480v80H120Zm0-200v-80h720v80H120Z"/></svg>,
    check:
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M389-240.7 166.93-461.76l74.27-75.26L389-388.98l331.57-330.56 74.26 74.02L389-240.7Z"/></svg>,
    add:
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M423.41-424.17H164.65v-113.18h258.76v-259h113.18v259h258.76v113.18H536.59v258.76H423.41v-258.76Z"/></svg>
}

/** SVG icon */
function Icon({ title, icon, className }) {
    return (
        <div className={`icon ${className}`} data-title={title}>
            {icon_data[icon] ??
                <svg style={{"width":0}}></svg>}
        </div>
    )
}

export default Icon;
