// Components
// import Icon from './components/Icon.js'
import WorldItem from './WorldItem.js'

// Data
import worlds from './data/worlds.json'
import { images } from './data/data.js'
// import { uuids, tags } from './data/data.json'

import { capitalizeFL } from './functions'


function WorldsList({ sort, filters }) {
    let list = Object.entries(worlds);
    let filter_count = 0;

    // Sort
    if(sort !== 'relevant') {
        // Remove headings
        list = list.filter(([, value]) => value !== 0);

        /** Sort functions */
        const sortFunctions = {
            'chronological': ([,a], [,b]) => {
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
            },
            'screenshots': (a, b) => images[b?.[0]]?.length - images[a?.[0]]?.length
        }

        list = list.sort(sortFunctions[sort]);

        /* Headings functions */
        const headingsFunctions = {
            'chronological': (i, key, value) => {
                // Get start year
                const year = new Date(value.startDate).getYear() + 1900;
                
                // Return
                return isNaN(year) ? null : `${year}`
            },
            'screenshots': (i, key, value) => {
                const imageCount = images?.[key]?.length;

                console.log(key, imageCount);

                if(imageCount < 19) return 'No screenshots';

                let divide = 100;
                if(imageCount < 100) divide = 25;
                let min = imageCount - imageCount % divide;
                const headname = `${min} - ${min+divide}`;

                // Return
                return headname;
            }
        }

        // Loop list and create headings
        let headings = {};
        for(let i in list) {
            const [key, value] = list[i];
            const name = headingsFunctions?.[sort]?.(i, key, value);
            if(name === null || headings[name]) continue; // Null or already exists

            // Add heading
            list.splice(i, 0, [name, 0]);
            headings[name] = true;
        }
    }

    return (
        <div className="worlds_list container">
            {/* Sort seperator */}
            {<h2 className="separator">{capitalizeFL(sort)}</h2>}

            {/* Worlds list */}
            {list.map((arr, index) => {
                let [name, data] = arr;

                // Filters
                if(
                    (
                        (filters['Vanilla'] && data.modded !== 'Vanilla') ||
                        (filters['Modded'] && data.modded === 'Vanilla') ||
                        (filters['Singleplayer'] && data.mode !== 'Singleplayer') ||
                        (filters['Multiplayer'] && data.mode !== 'Multiplayer') ||
                        (filters['Downloadable'] && !data.download) ||
                        (filters['Statistics'] && !data.stats) ||
                        (filters['Survival'] && data.gamemode !== 'Survival') ||
                        (filters['Creative'] && data.gamemode !== 'Creative')
                    )
                    && data !== 0
                ) {
                    filter_count++;
                    return null;
                }

                return data === 0 ?
                    <h2 className="separator" key={index}>{name}</h2> :
                    <WorldItem name={name} data={data} animDelay={index} sort={sort} key={index}/>;
            })}

            {/* Excluded by filter */}
            {filter_count > 0 ? <p className="center alt_text">{filter_count} results didn't match your filter</p> : ''}
        </div>
    )
}

export default WorldsList;
